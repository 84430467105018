import Toast from '@/components/molecules/Toast/Toast';
import { IToastAction, useToastContext } from '@/lib/hooks/toasts/Toast';
import { useCheckAndDispatchToastFromStorage } from '@/lib/hooks/toasts/ToastStorage';
import { useEffect } from 'react';

/**
 * IToastHandler
 *
 * @interface
 */
export interface IToastHandler {
  /** An Initial Toast to Show when rendering */
  initialToast?: IToastAction;
  /**
   * How long to show a non persistent toast.
   *
   * @default 2000
   */
  timeToShow?: number;
}

/**
 * Toast Handler Handles Displaying Toast Messages
 *
 * @param {IToastHandler} props - The props for the Toast Handler component
 * @returns {React.FC<IToastHandler>} Toast Handler Component
 */
const ToastHandler: React.FC<IToastHandler> = ({
  initialToast,
  timeToShow,
}) => {
  const toastContext = useToastContext();
  const toastDispatch = toastContext.dispatch;
  const toastState = toastContext.state;

  /** If Initial Toast Action Create an Initial Toast */
  useEffect(() => {
    if (initialToast) {
      toastDispatch(initialToast);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCheckAndDispatchToastFromStorage();

  /** Close Toast Dispatches a Delete Toast action when we close the Toast */
  const closeToast = () => {
    const currentToast = toastState.toasts[0];
    toastDispatch({
      type: 'DELETE_TOAST',
      toast: {
        type: currentToast.type,
        message: currentToast.message,
        id: currentToast.id,
        persistent: currentToast.persistent,
      },
    });
  };

  if (toastState.toasts.length > 0) {
    if (!toastState.toasts[0].persistent) {
      setTimeout(() => {
        closeToast();
      }, timeToShow);
    }
  }

  return (
    <div data-testid="toast-handler">
      {toastState.toasts.length > 0 ? (
        <Toast {...toastState.toasts[0]} closeClick={closeToast}>
          {toastState.toasts[0].message}
        </Toast>
      ) : null}
    </div>
  );
};
ToastHandler.defaultProps = {
  timeToShow: 3000,
};

export default ToastHandler;
